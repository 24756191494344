import Swal from "sweetalert2";

export function formatMoney (amount?: string, currency: string = '$'): string | null {
    if (amount === undefined || amount === null || amount === '') {
        return null;
    }
    const amt = Number(amount);

    return `${currency}${amt.toLocaleString().split('.')[0]}.${amt.toFixed(2).split('.')[1]}`;
}

export function formatDate (date: string): string | null {
    if (date === undefined || date === null) {
        return null;
    }
    return new Date(date).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}

export function toast(title: string, icon: string = 'success'): void {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    // @ts-ignore
    Toast.fire({title, icon});
}
